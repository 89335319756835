<template>
  <!--申请售后-->
  <el-dialog-x-dialog :title="title" :visible.sync="dialogApply">
    <el-form :model="txInfoFrom" ref="txInfoFrom" :rules="txInfoFromRules" label-width="135px">
      <el-form-item label="售后类型" prop="refund_type">
        <el-select style="width: 150px;" size="medium" v-model="txInfoFrom.refund_type" placeholder="请选择售后类型" clearable>
          <el-option label="仅退款" value="SHOP_REFUND"></el-option>
          <el-option label="退款退货" value="RETURN_GOODS"></el-option>
          <el-option label="换货" value="EXCHANGE_GOODS"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申请原因" prop="refund_reason">
        <el-select style="width: 150px;" size="medium" v-model="txInfoFrom.refund_reason" placeholder="请选择申请原因"
          clearable>
          <el-option label="长时间未收到货" value="长时间未收到货"></el-option>
          <el-option label="商品质量有问题" value="商品质量有问题"></el-option>
          <el-option label="其他" value="其他"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="问题描述">
        <el-input style="width: 450px;" type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" maxlength="250"
          v-model="txInfoFrom.refund_desc"></el-input>
      </el-form-item>
      <el-form-item label="图片凭证" prop="recharge_imgs">
        <template v-if="fileList.length > 0">
          <div class="background-img" v-for="(item, index) in fileList" :key="index">
            <img :src="item.url" alt="" />
            <div class="mantle">
              <i class="el-icon-delete" @click="handleRemove(index)"></i>
              <el-button round size="mini" class="btn-change-img" @click="handleChangeImg(index)">更换图片</el-button>
            </div>
          </div>
        </template>
        <el-upload :limit="9" accept=".jpg,.png,.gif" :show-file-list="false" :file-list="fileList" ref="uploadFile"
          :action="`${MixinUploadApi}?scene=goods`" list-type="picture-card"
          :on-exceed="e => this.$message.error('超出最大上传数量')" :before-upload="beforeUpload"
          :on-success="handleUploadSuccess">
          <i class="el-icon-plus"></i>
          <div class="el-upload__tip" slot="tip">可最多9张图片凭证，单张大小不可超过2M</div>
        </el-upload>

      </el-form-item>
      <el-form-item style="margin:0" v-show="['EXCHANGE_GOODS', 'RETURN_GOODS'].includes(txInfoFrom.refund_type)"
        label="退货方式">
        <el-radio-group v-model="txInfoFrom.refund_ship_type" size="small">
          <el-radio-button :label="1">客户发货</el-radio-button>
          <el-radio-button v-if="isJDGoods" :label="2">上门取件</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-show="['EXCHANGE_GOODS', 'RETURN_GOODS'].includes(txInfoFrom.refund_type)">
        <p>退货收货人：{{ refund_person }} 联系电话：{{ refund_tel }}</p>
        <p>退货地址：{{ refund_address }}</p>
      </el-form-item>
      <el-form-item label=" 上门取件地址" v-if="txInfoFrom.refund_type != 'SHOP_REFUND' && txInfoFrom.refund_ship_type == 2"
        prop="mobile">
        <el-form-item label-width="80px" style="margin-bottom:22px" label="姓名" prop="pickup_name">
          <el-input style="width: 200px;" placeholder="请输入姓名" :maxlength="50"
            v-model.trim="txInfoFrom.pickup_name"></el-input>
        </el-form-item>
        <el-form-item label-width="80px" style="margin-bottom:22px" label="手机号" prop="pickup_mobile">
          <el-input style="width: 200px;" placeholder="请输入手机号" :maxlength="11"
            v-model.trim="txInfoFrom.pickup_mobile"></el-input>
        </el-form-item>
        <el-form-item label-width="80px" style="margin-bottom:22px" label="取件地区" prop="pickup_county_id">
          <el-select @change="changeProvince('province')" size="small" class="choose-machine center"
            v-model="txInfoFrom.pickup_province_id" placeholder="省/直辖市" style="width: 100px; margin-right: 5px"
            clearable>
            <el-option v-for="(item, index) in this.provinceList" :label="item.name" :value="item.id" :key="index">
            </el-option>
          </el-select>
          <el-select @change="changeProvince('city')" size="small" class="choose-machine center"
            v-model="txInfoFrom.pickup_city_id" placeholder="城市/地区" style="width: 100px; margin-right: 5px" clearable>
            <el-option v-for="(item, index) in this.cityList" :label="item.name" :value="item.id" :key="index">
            </el-option>
          </el-select>
          <el-select @change="changeProvince('county')" size="small" class="choose-machine center"
            v-model="txInfoFrom.pickup_county_id" placeholder="县/区" style="width: 100px; margin-right: 5px" clearable>
            <el-option v-for="(item, index) in this.countyList" :label="item.name" :value="item.id" :key="index">
            </el-option>
          </el-select>
          <el-select size="small" @change="changeProvince('town')" class="choose-machine center"
            v-model="txInfoFrom.pickup_town_id" placeholder="乡镇/街道" style="width: 100px; margin-right: 5px" clearable>
            <el-option v-for="(item, index) in this.townList" :label="item.name" :value="item.id" :key="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="80px" style="margin-bottom:22px" label="详细地址" prop="pickup_address">
          <el-input style="width: 400px" placeholder="请输入详细地址" :maxlength="100"
            v-model="txInfoFrom.pickup_address"></el-input>
        </el-form-item>
      </el-form-item>

      <el-form-item label="换货收货地址" v-if="txInfoFrom.refund_type == 'EXCHANGE_GOODS'">
        <p style="display:inline-block;margin-right:10px">该地址是商城回寄给用户的地址</p>
        <el-checkbox v-if="txInfoFrom.refund_ship_type == 2"
          v-model="txInfoFrom.exchange_shipping_is_pickup">与上门取件地址保持一致</el-checkbox>
      </el-form-item>
      <el-form-item
        v-if="(txInfoFrom.refund_type == 'EXCHANGE_GOODS') && (txInfoFrom.refund_ship_type == 1 || txInfoFrom.exchange_shipping_is_pickup == false)">
        <el-form-item label-width="80px" style="margin-bottom:22px" label="姓名" prop="exchange_shipping_name">
          <el-input style="width: 200px;" placeholder="请输入姓名" :maxlength="50"
            v-model.trim="txInfoFrom.exchange_shipping_name"></el-input>
        </el-form-item>
        <el-form-item label-width="80px" style="margin-bottom:22px" label="手机号" prop="exchange_shipping_mobile">
          <el-input style="width: 200px;" placeholder="请输入手机号" :maxlength="11"
            v-model.trim="txInfoFrom.exchange_shipping_mobile"></el-input>
        </el-form-item>

        <el-form-item label-width="80px" style="margin-bottom:22px" label="收货地区" prop="exchange_shipping_county_id">
          <el-select @change="changeProvince_exchange('province')" size="small" class="choose-machine center"
            v-model="txInfoFrom.exchange_shipping_province_id" placeholder="省/直辖市"
            style="width: 100px; margin-right: 5px" clearable>
            <el-option v-for="(item, index) in this.exchange_provinceList" :label="item.name" :value="item.id"
              :key="index">
            </el-option>
          </el-select>
          <el-select @change="changeProvince_exchange('city')" size="small" class="choose-machine center"
            v-model="txInfoFrom.exchange_shipping_city_id" placeholder="城市/地区" style="width: 100px; margin-right: 5px"
            clearable>
            <el-option v-for="(item, index) in this.exchange_cityList" :label="item.name" :value="item.id" :key="index">
            </el-option>
          </el-select>
          <el-select @change="changeProvince_exchange('county')" size="small" class="choose-machine center"
            v-model="txInfoFrom.exchange_shipping_county_id" placeholder="县/区" style="width: 100px; margin-right: 5px"
            clearable>
            <el-option v-for="(item, index) in this.exchange_countyList" :label="item.name" :value="item.id"
              :key="index">
            </el-option>
          </el-select>
          <el-select @change="changeProvince_exchange('town')" size="small" class="choose-machine center"
            v-model="txInfoFrom.exchange_shipping_town_id" placeholder="乡镇/街道" style="width: 100px; margin-right: 5px"
            clearable>
            <el-option v-for="(item, index) in this.exchange_townList" :label="item.name" :value="item.id" :key="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="80px" style="margin-bottom:22px" label="详细地址" prop="exchange_shipping_address">
          <el-input style="width: 400px;" placeholder="请输入详细地址" :maxlength="100"
            v-model="txInfoFrom.exchange_shipping_address"></el-input>
        </el-form-item>
      </el-form-item>
      <div class="dialog-footer" style="padding:20px 0;text-align:center">
        <el-button @click="cancel" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="accountSubmit">提交申请</el-button>
      </div>
    </el-form>
  </el-dialog-x-dialog>
</template>
<script>
import { RegExp } from '@/../ui-utils'
import { refundsApplyDs, sellerAddress, updaterefundsApplyDs } from "@/api/refund";
import * as API_goods from "@/api/goods";
import * as API_order from "@/api/order";
export default {
  name: "Applyforaftersales",
  props: {
    title: {
      type: String,
      default: ''
    },
    sellerId: {
      type: Number,
      default: 0
    },
    isJDGoods: {
      type: Boolean,
      default: false
    },
    isShow: {
      type: Boolean,
      default: false
    },
    formdata: {
      type: Object,
      default: function () {
        return {
          order_sn: "",
          sku_id: 0
        }
      }
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      replace_image: '',//要更换的图片下标
      //供应商后台配置的售后地址
      refund_person: '',
      refund_tel: '',
      refund_address: '',
      //上门取件地址
      provinceList: [],
      cityList: [],
      countyList: [],
      townList: [],
      //换货收货地址
      exchange_provinceList: [],
      exchange_cityList: [],
      exchange_countyList: [],
      exchange_townList: [],

      fileList: [],//上传图片列表
      txInfoFrom: {
        order_sn: this.formdata.order_sn,//订单编号
        sku_id: this.formdata.sku_id,//货品Id
        refund_type: '',//售后类型
        refund_reason: '',//售后原因
        refund_desc: '',//问题描述
        refund_img: '',//图片凭证
        refund_ship_type: 1,//退货方式
        //上门取件地址
        pickup_name: '',//上门取件姓名
        pickup_mobile: '',//上门取件手机号
        pickup_province_id: '',//上门取件省id
        pickup_province: '',//上门取件省
        pickup_city_id: '',//上门取件市id
        pickup_city: '',//上门取件市
        pickup_county_id: '',//上门取件区id
        pickup_county: '',//上门取件区
        pickup_town_id: '',//上门取件镇id
        pickup_town: '',//上门取件镇
        pickup_address: '',//上门取件详细地址
        exchange_shipping_is_pickup: true,//与上门取件地址保持一致
        //换货收货地址
        exchange_shipping_name: '',//收货人姓名
        exchange_shipping_mobile: '',//收货手机号
        exchange_shipping_province_id: '',//收货省id
        exchange_shipping_province: '',//收货省
        exchange_shipping_city_id: '',//收货市id
        exchange_shipping_city: '',//收货市
        exchange_shipping_county_id: '',//收货区id
        exchange_shipping_county: '',//收货区
        exchange_shipping_town_id: '',//收货镇id
        exchange_shipping_town: '',//收货镇
        exchange_shipping_address: '',//收货详细地址

      },
      //售后表单验证
      txInfoFromRules: {
        refund_type: [{
          required: true,
          message: "请选择售后类型",
          trigger: "change"
        }],
        refund_reason: [{
          required: true,
          message: "请选择申请原因",
          trigger: "change"
        }],
        //上门取件地址
        pickup_name: [{
          required: true,
          message: "请填写姓名",
          trigger: "blur"
        }],
        pickup_mobile: [{
          required: true,
          message: "请填写手机号",
          trigger: "blur"
        }, {
          validator: (rule, value, callback) => {
            if (!RegExp.mobile.test(value)) {
              callback(new Error('手机号码格式有误！'))
            } else {
              callback()
            }
          }
        }],
        pickup_county_id: [{
          required: true,
          message: "请选择取件地区",
          trigger: "change"
        }],
        pickup_address: [{
          required: true,
          message: "请填写详细地址",
          trigger: "blur"
        }],

        //换货收货地址
        exchange_shipping_name: [{
          required: true,
          message: "请填写收货人姓名",
          trigger: "blur"
        }],
        exchange_shipping_mobile: [{
          required: true,
          message: "请填写手机号",
          trigger: "blur"
        }, {
          validator: (rule, value, callback) => {
            if (!RegExp.mobile.test(value)) {
              callback(new Error('手机号码格式有误！'))
            } else {
              callback()
            }
          }
        }],
        exchange_shipping_county_id: [{
          required: true,
          message: "请选择收货地区",
          trigger: "change"
        }],
        exchange_shipping_address: [{
          required: true,
          message: "请填写详细地址",
          trigger: "blur"
        }],
      },
    }
  },
  computed: {
    dialogApply: {
      get () {
        return this.isShow
      },
      set (val) {
        if (!val) {
          this.fileList = [];
          this.$emit('cancel')
        }
      },
    }
  },
  watch: {
    formdata: {
      handler (newValue) {
        //供应商后台配置的售后地址
        this.refund_person = '';
        this.refund_tel = '';
        this.refund_address = '';
        sellerAddress(this.sellerId).then(res => {

          this.refund_person = res.refund_person;
          this.refund_tel = res.refund_tel;
          if (res.refund_province) {
            this.refund_address = res.refund_province + res.refund_city + res.refund_county + (res.refund_town || '') + res.refund_address;
          }
        })
        this.txInfoFrom = {
          ...newValue
        }
        if (newValue.refund_img !== '') {
          const imageList = newValue.refund_img.split(',')
          imageList.map((item, index) => {
            this.fileList.push({ name: index, url: item })
          })
        }
        if (newValue.exchange_shipping_is_pickup == 1) {
          this.txInfoFrom.exchange_shipping_is_pickup = true;
        } else {
          this.txInfoFrom.exchange_shipping_is_pickup = false;
        }
        // 获取上门取件区域数据
        if (this.txInfoFrom.pickup_province_id) {
          this.getAreaHandle_pickup("city", { id: this.txInfoFrom.pickup_province_id });
        }
        if (this.txInfoFrom.pickup_city_id) {
          this.getAreaHandle_pickup("county", { id: this.txInfoFrom.pickup_city_id });
        }
        if (this.txInfoFrom.pickup_county_id) {
          this.getAreaHandle_pickup("town", { id: this.txInfoFrom.pickup_county_id })
        }
        // 获取换货收货区域数据
        if (this.txInfoFrom.exchange_shipping_province_id) {
          this.getAreaHandle_exchange("city", { id: this.txInfoFrom.exchange_shipping_province_id });
        }
        if (this.txInfoFrom.exchange_shipping_city_id) {
          this.getAreaHandle_exchange("county", { id: this.txInfoFrom.exchange_shipping_city_id });
        }
        if (this.txInfoFrom.exchange_shipping_county_id) {
          this.getAreaHandle_exchange("town", { id: this.txInfoFrom.exchange_shipping_county_id })
        }
      },
      deep: true,
    }
  },
  mounted () {
    this.getAreaHandle_pickup("province");
    this.getAreaHandle_exchange("province");
  },
  methods: {

    /**格式化地址数据 */
    dataHandle (data) {
      let returnData = [];
      const keys = Object.keys(data);
      const values = Object.values(data);
      keys.forEach((key, index) => {
        const data = {
          name: key,
          id: values[index],
        };
        returnData.push(data);
      });
      return returnData;
    },
    /**
     * 获取上门取件区域数据
     * @param type
     * @param data
     */
    getAreaHandle_pickup (type, data = {}) {
      console.log(type);
      if (type === "province") {
        API_goods.getJDProvince().then((res) => {
          this.provinceList = this.dataHandle(res.data.result);
        });
      } else if (type === "city") {
        API_goods.getJDCity(data.id).then((res) => {
          this.cityList = this.dataHandle(res.data.result);
        });
      } else if (type === "county") {
        API_goods.getJDCounty(data.id).then((res) => {
          this.countyList = this.dataHandle(res.data.result);
        });
      } else if (type === "town") {
        API_goods.getJDTown(data.id).then((res) => {
          this.townList = this.dataHandle(res.data.result);
        });
      }
    },
    /**
     * 上门取件change事件
     * @param {*} type 
     */
    changeProvince (type) {
      if (type == "province") {
        this.txInfoFrom.pickup_city_id = "";//上门取件市id
        this.txInfoFrom.pickup_city = "";//上门取件市
        this.txInfoFrom.pickup_county_id = "";//上门取件区id
        this.txInfoFrom.pickup_county = "";//上门取件区
        this.txInfoFrom.pickup_town_id = "";//上门取件镇id
        this.txInfoFrom.pickup_town = "";//上门取件镇
        if (this.txInfoFrom.pickup_province_id === "") {
          this.txInfoFrom.pickup_province = "",//上门取件省
            this.getAreaHandle_pickup("province");
        } else {
          const findProvince = this.provinceList.find(item => item.id === this.txInfoFrom.pickup_province_id)
          this.txInfoFrom.pickup_province = findProvince.name;//上门取件省
          this.getAreaHandle_pickup("city", { id: this.txInfoFrom.pickup_province_id });
        }
      } else if (type == "city") {
        this.txInfoFrom.pickup_county_id = "";//上门取件区id
        this.txInfoFrom.pickup_county = "";//上门取件区
        this.txInfoFrom.pickup_town_id = "";//上门取件镇id
        this.txInfoFrom.pickup_town = "";//上门取件镇
        if (this.txInfoFrom.pickup_province_id === "") return;
        if (this.txInfoFrom.pickup_city_id !== "") {
          const findCity = this.cityList.find(item => item.id === this.txInfoFrom.pickup_city_id)
          this.txInfoFrom.pickup_city = findCity.name;//上门取件市
          this.getAreaHandle_pickup("county", { id: this.txInfoFrom.pickup_city_id });
        }
      } else if (type == "county") {
        this.txInfoFrom.pickup_town_id = "";
        if (this.txInfoFrom.pickup_city_id === "") return;
        if (this.txInfoFrom.pickup_county_id !== "") {
          const findCounty = this.countyList.find(item => item.id === this.txInfoFrom.pickup_county_id)
          this.txInfoFrom.pickup_county = findCounty.name;//上门取件区
          this.getAreaHandle_pickup("town", { id: this.txInfoFrom.pickup_county_id });
        }
      } else if (type == "town") {
        this.txInfoFrom.pickup_town = "";//上门取件县
        if (this.txInfoFrom.pickup_town_id === "") return;
        if (this.txInfoFrom.pickup_town_id !== "") {
          const findTown = this.townList.find(item => item.id === this.txInfoFrom.pickup_town_id)
          this.txInfoFrom.pickup_town = findTown.name;//上门取件县
        }
      }
    },
    /**
     * 获取换货收货区域数据
     * @param type
     * @param data
     */
    getAreaHandle_exchange (type, data = {}) {
      if (type === "province") {
        API_goods.getJDProvince().then((res) => {
          this.exchange_provinceList = this.dataHandle(res.data.result);
        });
      } else if (type === "city") {
        API_goods.getJDCity(data.id).then((res) => {
          this.exchange_cityList = this.dataHandle(res.data.result);
        });
      } else if (type === "county") {
        API_goods.getJDCounty(data.id).then((res) => {
          this.exchange_countyList = this.dataHandle(res.data.result);
        });
      } else if (type === "town") {
        API_goods.getJDTown(data.id).then((res) => {
          this.exchange_townList = this.dataHandle(res.data.result);
        });
      }
    },
    /**
     * 换货收货change事件
     * @param {*} type 
     */
    changeProvince_exchange (type) {
      if (type == "province") {
        this.txInfoFrom.exchange_shipping_city_id = "";//上门取件市id
        this.txInfoFrom.exchange_shipping_city = "";//上门取件市
        this.txInfoFrom.exchange_shipping_county_id = "";//上门取件区id
        this.txInfoFrom.exchange_shipping_county = "";//上门取件区
        this.txInfoFrom.exchange_shipping_town_id = "";//上门取件镇id
        this.txInfoFrom.exchange_shipping_town = "";//上门取件镇
        if (this.txInfoFrom.exchange_shipping_province_id === "") {
          this.txInfoFrom.exchange_shipping_province = "",//上门取件省
            this.getAreaHandle_exchange("province");
        } else {
          const findProvince = this.exchange_provinceList.find(item => item.id === this.txInfoFrom.exchange_shipping_province_id)
          this.txInfoFrom.exchange_shipping_province = findProvince.name;//上门取件省
          this.getAreaHandle_exchange("city", { id: this.txInfoFrom.exchange_shipping_province_id });
        }
      } else if (type == "city") {
        this.txInfoFrom.exchange_shipping_county_id = "";//上门取件区id
        this.txInfoFrom.exchange_shipping_county = "";//上门取件区
        this.txInfoFrom.exchange_shipping_town_id = "";//上门取件镇id
        this.txInfoFrom.exchange_shipping_town = "";//上门取件镇
        if (this.txInfoFrom.exchange_shipping_province_id === "") return;
        if (this.txInfoFrom.exchange_shipping_city_id !== "") {
          const findCity = this.exchange_cityList.find(item => item.id === this.txInfoFrom.exchange_shipping_city_id)
          this.txInfoFrom.exchange_shipping_city = findCity.name;//上门取件市
          this.getAreaHandle_exchange("county", { id: this.txInfoFrom.exchange_shipping_city_id });
        }
      } else if (type == "county") {
        this.txInfoFrom.exchange_shipping_town_id = "";
        if (this.txInfoFrom.exchange_shipping_city_id === "") return;
        if (this.txInfoFrom.exchange_shipping_county_id !== "") {
          const findCounty = this.exchange_countyList.find(item => item.id === this.txInfoFrom.exchange_shipping_county_id)
          this.txInfoFrom.exchange_shipping_county = findCounty.name;//上门取件区
          this.getAreaHandle_exchange("town", { id: this.txInfoFrom.exchange_shipping_county_id });
        }
      } else if (type == "town") {
        this.txInfoFrom.exchange_shipping_town = "";//上门取件县
        if (this.txInfoFrom.exchange_shipping_town_id === "") return;
        if (this.txInfoFrom.exchange_shipping_town_id !== "") {
          const findTown = this.exchange_townList.find(item => item.id === this.txInfoFrom.exchange_shipping_town_id)
          this.txInfoFrom.exchange_shipping_town = findTown.name;//上门取件县
        }
      }
    },
    /**关闭申请售后弹窗 */
    cancel () {
      this.txInfoFrom = {
        order_sn: this.formdata.order_sn,//订单编号
        sku_id: this.formdata.sku_id,//货品Id
        refund_type: '',//售后类型
        refund_reason: '',//售后原因
        refund_desc: '',//问题描述
        refund_img: '',//图片凭证
        refund_ship_type: 1,//退货方式
        //上门取件地址
        pickup_name: '',//上门取件姓名
        pickup_mobile: '',//上门取件手机号
        pickup_province_id: '',//上门取件省id
        pickup_province: '',//上门取件省
        pickup_city_id: '',//上门取件市id
        pickup_city: '',//上门取件市
        pickup_county_id: '',//上门取件区id
        pickup_county: '',//上门取件区
        pickup_town_id: '',//上门取件镇id
        pickup_town: '',//上门取件镇
        pickup_address: '',//上门取件详细地址
        exchange_shipping_is_pickup: true,//与上门取件地址保持一致
        //换货收货地址
        exchange_shipping_name: '',//收货人姓名
        exchange_shipping_mobile: '',//收货手机号
        exchange_shipping_province_id: '',//收货省id
        exchange_shipping_province: '',//收货省
        exchange_shipping_city_id: '',//收货市id
        exchange_shipping_city: '',//收货市
        exchange_shipping_county_id: '',//收货区id
        exchange_shipping_county: '',//收货区
        exchange_shipping_town_id: '',//收货镇id
        exchange_shipping_town: '',//收货镇
        exchange_shipping_address: '',//收货详细地址
      }
      this.$emit('cancel');
    },
    //提交状态验证
    formdatafilter () {
      //获取订单编号 商品id
      let data = {
        ...this.txInfoFrom
      }
      if (this.txInfoFrom.refund_type == 'SHOP_REFUND') {//仅退款状态
        data.is_need_send = 0 //是否须要寄回0不需要;1需要
        data.receiving_status = 0 //实际收货状态0未收货;1已收货
      } else {
        data.is_need_send = 1
        data.receiving_status = 1
      }
      if (this.txInfoFrom.pickup_town_id) {//上门取货地址ID
        data.pickup_address_id = this.txInfoFrom.pickup_town_id
      } else if (this.txInfoFrom.pickup_county_id) {
        data.pickup_address_id = this.txInfoFrom.pickup_county_id
      }
      if (this.txInfoFrom.exchange_shipping_town_id) {//换货收货地址ID
        data.address_id = this.txInfoFrom.exchange_shipping_town_id
      } else if (this.txInfoFrom.exchange_shipping_county_id) {
        data.address_id = this.txInfoFrom.exchange_shipping_county_id
      }
      if (this.txInfoFrom.refund_ship_type == 1) {//选择客户发货时 上门取件地址保持一致=否
        this.txInfoFrom.exchange_shipping_is_pickup = false
      }
      this.txInfoFrom.exchange_shipping_is_pickup ? data.exchange_shipping_is_pickup = 1 : data.exchange_shipping_is_pickup = 0
      return data
    },
    //提交售后申请
    accountSubmit () {
      this.$refs.txInfoFrom.validate(valid => {
        if (valid) {
          const forms = this.formdatafilter()
          if (this.isEdit) {//修改售后申请
            updaterefundsApplyDs(forms).then(res => {
              this.$message.success('售后申请提交成功')
              this.$emit('refresh');
            })
          } else {//创建售后申请
            refundsApplyDs(forms).then(res => {
              this.$message.success('售后申请提交成功')
              this.$emit('refresh');
            })
          }
        }
      })
    },
    //删除文件
    handleRemove (el) {
      this.fileList.splice(el, 1);
      const urlList = this.fileList.map(item => item.url);
      this.txInfoFrom.refund_img = urlList.toString();
    },
    // 更换图片
    handleChangeImg (index) {
      this.replace_image = index;
      this.$refs.uploadFile.clearFiles();
      console.log(this.$refs.uploadFile.$children)
      this.$refs.uploadFile.$children[0].$refs.input.click();
    },
    // 上传之前校验
    beforeUpload (file) {
      let result = true;
      const fileType = file.name.split(".")[1];
      const fileSize = parseFloat((file.size / 1024 / 1024).toFixed(2));
      if (fileType !== "jpg" && fileType !== "png" && fileType !== "gif") {
        result = false;
      }
      if (fileSize > 10) {
        result = false;
      }
      if (!result) {
        this.$message.error("请按照提示信息上传图片");
        return false;
      }
    },

    // 上传成功回调
    handleUploadSuccess (response, file, fileList) {
      let obj = {
        uid: file.uid,
        url: response.url
      }
      if (this.replace_image !== '') {
        this.fileList.splice(this.replace_image, 1, obj)
        this.replace_image = '';
      } else {
        this.fileList.push(obj)
      }
      const urlList = this.fileList.map(item => item.url);
      this.txInfoFrom.refund_img = urlList.toString();
    },

  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-scrollbar__wrap {
  margin-right: -7px !important;
}

/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
}

/deep/ .el-scrollbar {
  height: 560px;
}

/deep/ .el-form-item__content {
  .background-img {
    display: inline-block;
    width: 80px;
    height: 80px;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    cursor: pointer;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .mantle {
      width: 80px;
      height: 80px;
      display: none;
      position: absolute;
      top: 0;
      background-color: black;
      opacity: 0.5;
      border-radius: 10px;

      .el-icon-delete {
        font-size: 15px;
        position: relative;
        left: 77%;
        top: -7%;
      }

      .btn-change-img {
        display: block;
        margin: 0 auto;
        position: relative;
        top: -11%;
        font-size: 7px;
        background-color: black;
        opacity: 0.6;
        color: white;
      }
    }
  }

  .background-img:hover {
    .mantle {
      display: block;
    }
  }
}
</style>